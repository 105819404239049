.tui-image-editor-container {
    background: transparent;
    width: 99%;
    height: 500px !important;
}
.tui-image-editor-header-logo>img{
  display: none !important;
}
.tui-image-editor-container .tui-image-editor-header-buttons button, .tui-image-editor-container .tui-image-editor-header-buttons div, .tui-image-editor-container .tui-image-editor-controls-buttons button, .tui-image-editor-container .tui-image-editor-controls-buttons div{  display: none;
}
.tui-image-editor-container .tui-image-editor-main{
  background-color: white;
}
.tui-image-editor-container .tui-image-editor-header{
  height: 100px;
  background-color: white !important;
}
.tui-image-editor-container .tui-image-editor-controls{
    /* width: 99%; */
    left: 1px;
    background-color: rgba(75, 75, 75, 1);
    /* border-radius: 5px !important; */
}
.tui-image-editor-container .tui-image-editor-help-menu.top {
    padding: 6px;
    left: 48%;
}
.tui-image-editor-container .tui-image-editor-icpartition{
    margin-bottom: -8px;
}
.tui-image-editor-header-buttons{
    display: none;
}
/*-----------------
	antd css
-----------------------*/

.upload-list-inline .ant-upload-list-item {
    overflow: hidden !important;
    float: left;
    width: 115px;
    margin-right: 8px;
    margin-left: 20px;
}
.ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item {
    position: relative;
    height: 85px;
    padding: 8px;
    border: none;
    border-radius: 2px;
}
  .upload-list-inline [class*="-upload-list-rtl"] .ant-upload-list-item {
    float: right;
  }

  .ant-btn{
      border:none !important;
      height: 80px;
      
  }
.ant-btn img{
    margin-bottom: 46px;
}
  .ant-upload.ant-upload-select {
    display: contents;
}

.ant-upload-list::before {
    display: block;
    content: '';
}
.ant-upload-list-text-container::before, .ant-upload-list-picture-container::before {
    display: table;
    width: 0;
    height: 0;
    content: '';
}

.ant-upload-list-text .ant-upload-span, .ant-upload-list-picture .ant-upload-span {
    
    display: block;
    
    align-items: center;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
    width: 97px;
    height: 71px;
    line-height: 54px;
    text-align: center;
    opacity: 0.8;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail img, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    display: block;
    width: 97px;
    height: 71px;
    overflow: hidden;
}
.ant-upload-list-picture .ant-upload-list-item-name, .ant-upload-list-picture-card .ant-upload-list-item-name {
    display: initial;
    padding: 10px;
}
.ant-upload-list-item-card-actions .anticon {
    color: red;
}


.ant-upload-list-text .ant-upload-list-item-name, .ant-upload-list-picture .ant-upload-list-item-name{
    display: none;
    font-size: 13px;
    padding: 0px 0px 0px 0px;
    position: absolute;
    top: 71px;
}

.ant-upload-list-text .ant-upload-list-item-card-actions, .ant-upload-list-picture .ant-upload-list-item-card-actions {
    position: absolute;
}

.ant-upload-list-item-card-actions.picture {
    top: 80px;
    line-height: 0;
}

.ant-upload-list-item-card-actions{
    display: none !important;
}
.image-tui-wrapper .video-wrapper{
    width: 600px;
    height: auto;
}
.case-completed .tui-image-editor-help-menu ,.case-completed .tui-image-editor-controls{
    display: none !important;
}
.case-completed .tui-image-editor-container{
    background-color: transparent;
}
@media(max-width:768px)
{
    .ant-upload-list{display: block}
    .tui-image-editor-container .tui-image-editor-help-menu.top{
		white-space: break-spaces !important;
		width: 300px !important;
	}
	.tui-image-editor-container .tui-image-editor-menu, .tui-image-editor-container .tui-image-editor-help-menu{
		white-space: initial;
	}

}

@media(min-width:768px)
{
    .ant-upload-list{display: contents}

}

.student-dashboard .tui-image-editor-container{
    background-color: transparent;
    border: 1px solid #C4C4C4;
}
