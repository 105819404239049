/* --------- text color --------- */

.text-active{
    color: rgba(14, 148, 231, 1) !important;
}


.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before{
    left: -28px !important;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
    left: -28px !important;
}
.justify-content-right{
    justify-content: right;
}
/*-----------------
	package card
-----------------------*/
.card{
    border: none !important;
}

.card-main{
    background-color: rgba(255, 255, 255, 1);
}
.card-main .card-main-title{
    color: rgba(0, 0, 0, 1);
    font-size: 36px;
    line-height: 42px;
    font-weight: bold;
}
.package-card {
    border: 1px solid rgba(215, 215, 215, 1);
    margin-bottom: 1.875rem;
	border-radius: 5px;
}
.card-body {
	padding: 1.5rem;
}
.card-header {
    background-color: #fff;
    border-bottom: none;
    padding: 2rem 1.5rem;
}
.card-title {
    margin-bottom: 0;
	color: rgba(14, 148, 231, 1);
	font-size: 18px;
	line-height: 21px;
	font-weight: bold;
}
.card-body-title{
	color: rgba(0, 0, 0, 1);
	font-size: 24px;
	line-height: 28px;
	font-weight: bold;
}
.status-title{
	color: rgba(0, 0, 0, 1);
	font-size: 18px;
	line-height: 28px;
	font-weight: 400;
    letter-spacing: 1px;
}
.card-body-sub-title{
	color: rgba(0, 0, 0, 1);
	font-size: 14px;
	line-height: 16px;
	font-weight: bold;
}
.card-text{
	color: rgba(75, 75, 75, 1);
	font-size: 14px;
	line-height: 16px;
}
.model-title{
   	color: rgba(0, 0, 0, 1);
	font-size: 18px;
	line-height: 28px;
	font-weight: 600;
    letter-spacing: 0.5px; 
}
.package-card .card-body .btn-primary{
	background: rgba(14, 148, 231, 1);
	color: rgba(255, 255, 255, 1);
	border-radius: 5px;
	font-size: 16px;
	line-height: 18px;
	font-weight: bold;
}
.package-card:hover{
    background-color: rgba(14, 148, 231, 1) ;
    color: rgba(255, 255, 255, 1) ;
    .card-header {
        background-color: rgba(14, 148, 231, 1) ;
    }
    .card-title {
        color: rgba(255, 255, 255, 1) ;
    }
    .card-body .card-body-title{
	    color: rgba(255, 255, 255, 1);
    }
    .card-body .card-body-sub-title{
	    color: rgba(255, 255, 255, 1);
    }
    .card-body .card-text{
	    color: rgba(255, 255, 255, 1);
    }

    .card-body .btn-primary{
        background-color: rgba(255, 255, 255, 1) !important;
        color: rgba(14, 148, 231, 1) !important;
        border: 2px solid rgba(255, 255, 255, 1);
    }
}
.search-detect {
    position: absolute;
    margin-top: -40px;
    right: 60px;
    font-size: 22px;
    color: rgba(14, 148, 231, 1) !important;
    border-radius: 5px;
    cursor: pointer;
}
.search-detect-cancel {
    position: absolute;
    margin-top: -40px;
    right: 60px;
    font-size: 22px;
    color: red !important;
    border-radius: 5px;
    cursor: pointer;
}
.booking-card{
    border: 1px solid #f0f0f0;
}
.booking-fee{
    .search-detect-cancel {
        position: initial;
        margin-top: -40px;
        right: 60px;
        font-size: 14px;
        color: red !important;
        border-radius: 5px;
        cursor: pointer;
    }
}
.coupon-message-success {
    font-size: 14px;
    color: green !important;
}
.coupon-message-failure {
    font-size: 14px;
    color: red !important;
}
.delete-dropdown{
    .btn-delete{
        background-color: transparent !important;
        color: rgba(0, 0, 0, 1) !important;
        border: none !important;
        padding: 0px !important;
    }
    .dropdown-toggle::after{
        display: none !important;
    }
    .dropdown-menu.show{
        position: absolute;
        margin-top: -15px !important;
        margin-right: 15px !important;
    }
    .dropdown-item{
        text-align: left;
        font-size: 16px;
    }
    .has-arrow-right{
        width: 0; 
        height: 0; 
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 8px solid rgba(255, 255, 255, 1);
        margin-left: 158px;
        margin-top: -5px;
        position: absolute;
    }
}
/*---------------------------
	Transaction students
-----------------------------*/

.tr-column-1{
    color: rgba(0, 0, 0, 1);
    font-weight: bold;
}

.ant-notification{
    text-align: left;
    z-index: 2000 !important;
}

.dropdown-toggle{
    span{
        color: rgba(75, 75, 75, 1);
        font-size:16px;
        margin-left: 10px;
    }
}

.acc-verification{
    color: rgba(171, 221, 218, 1);
    display: block;
    text-align: center;
    font-size: 120px;
}
.acc-verification-failed{
    color: rgba(244, 193, 80, 1);
    display: block;
    text-align: center;
    font-size: 120px;
}
.redNotif{
    color: red;
}
.form-group-password{
    padding-bottom: 100px !important;
}
.password-note{
    font-size: 12px;
}
.my-case{
    .card-table .table td, .card-table .table th{
        padding: 1rem 1rem ;
    }
}

input[type="date" i]::-webkit-calendar-picker-indicator {
    width: 90%;
    margin: 0;
    background-image: none;
    position: absolute;
}
select,
select option {
	color: rgba(0, 0, 0, 1) !important; 
    font-size: 14px !important;
	line-height: 16px !important;
	font-weight: bold !important;
}
.my-library {
     select{
        width: 370px;
     }
}
.fw-100{
    font-weight: 100;
}
.my-library {
    border-bottom: 1px solid #C4C4C4;
}
.break-spaces{
    white-space: pre-line;
}
.word-overflow{
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
select:invalid,
select option[value=""] {
  color: #999999 !important;
}
.ant-pagination-item-active{
    background-color: rgba(14, 148, 231, 1);
    color: white;
}
.form-login{
    .form-focus {
        height: 90px;
    }
}
.change-avatar-input{
    display: flex;
}
.change-avatar-input .default-input{
    height: 225px;
    width: 100%;
    position: absolute;
    opacity: 0;
}

.features-img{
    img{
        width: auto;
        height: 450px;
    }
}
.parent-div-loader{
    position: fixed !important;
    background-color: rgba(14, 148, 231, 0.5) !important;
    top: 50%;
    left: 50%;
    z-index: 1000;
    transform: translate(-50%,-50%);
}
.landing{
    .header {
        background: transparent ;
        position: absolute !important;
        .header-nav{
            background-color: transparent !important;
            border-bottom: none !important;
        }
    }
}
.search-box{
    .search-info{
        ul{
            list-style-type: none;
            padding: 0;
            max-height: 200px;
            width: 100%;
            overflow-y: scroll;
            position: absolute;
            li{
                background-color: rgba(255, 255, 255, 1);
                border-bottom: 1px solid #ccc;
                text-align: left;
                padding: 5px;
                font-size: 18px;
                a{
                    color: rgba(14, 148, 231, 1);
                }
                img{
                    height: 38px;
                    width: 40px;
                    border-radius: 50%;
                    margin-right: 10px;
                }
            }
        }
    }
}
.tutor-model-voice{
    .btn-file{
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background-color: rgba(14, 148, 231, 1);
        border: none;
        color: rgba(255, 255, 255, 1);
    }
}
.modal-bg-light-red{
    background-color:rgba(255, 199, 195, 1) !important;
	color:rgba(219, 79, 70, 1) !important;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    i{
        padding: 15px;
        font-size: 18px;
    }
}
.case-final-submit{
    color: red !important;
}
.sound-wave{
    display: block;
}
.voice-record{
    display: flex;
    justify-content: center;
    i{
        color: rgba(1, 75, 128, 1);
        font-size: 50px;
        margin-left: 10px;
        border-radius: 50%;
        border: none;
        background-color: rgba(255, 255, 255, 1);
    }
}
.voice-record{
    .active{
        i{
            color: red;
        }
    } 
}
.say-hi-text{
    text-align: center;
    padding: 250px 120px;
}
.say-hi-text{
    span{
        font-size: 20px;
    }
}

.nav-item{
    .avatar > img {
        border-radius: 50%;
    }
}

.swal2-styled.swal2-confirm{
    background-color: rgba(14, 148, 231, 1) !important;
}

.chat-preview{
    display: contents;
    img{
        height: 150px;
        width: 150px;
        border-radius: 5px;
    }
    .clear{
        position: absolute;
        left: 118px;
        background-color: white;
        color: black;
        border-radius: 20px;
        margin: 5px;
    }
}
.stl-viewer{
    border-radius: 5px;
}
.model-viewer {
  width: 100%;
  height: 400px;
  background-color: #70bcd1;
  --poster-color: #ffffff00;
}
.image-upload{
    display: grid;
    justify-content: center;
    padding: 5px;
    img{
        width: 110px;
        height: auto;
    }
}

#notfound {
  position: relative;
  height: 100vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.notfound {
  max-width: 920px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
}

.notfound .notfound-404 {
  position: absolute;
  height: 100px;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: -1;
}

.notfound .notfound-404 h1 {
  font-family: 'Maven Pro', sans-serif;
  color: #ececec;
  font-weight: 900;
  font-size: 276px;
  margin: 0px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.notfound h2 {
  font-family: 'Maven Pro', sans-serif;
  font-size: 46px;
  color: #000;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0px;
}

.notfound p {
  font-family: 'Maven Pro', sans-serif;
  font-size: 16px;
  color: #000;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 15px;
}

.notfound a {
  font-family: 'Maven Pro', sans-serif;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: rgba(14, 148, 231, 1);
  display: inline-block;
  padding: 16px 38px;
  border: 2px solid transparent;
  border-radius: 40px;
  color: #fff;
  font-weight: 400;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound a:hover {
  background-color: #fff;
  border-color: rgba(14, 148, 231, 1);
  color: rgba(14, 148, 231, 1);
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 h1 {
    font-size: 162px;
  }
  .notfound h2 {
    font-size: 26px;
  }
}

/*---------------------------
Transaction invoice students
-----------------------------*/
.anticon{
    vertical-align: 0.15em !important;
}
.invoice-content.digicase-invoice{
    text-align: left;   

    .invoice-details, .invoice-payment-details > li span{
        float: unset;
    }

    table 
    {
        thead
        {
            border-bottom: none;

            .heading-text{
                font-size: 18px;
                color: #272b41;
                font-weight: 600;
                margin-bottom: 8px;
            }
            
            tr 
            {
                th{
                    // border: unset;
                    text-align: left;
                }
            }
        }

        tbody{
            border-bottom: none;

            .content-text{
                color: #757575;
                font-weight: 500;
            }
        }

    }
}
.image-tui-wrapper{
    .bg-blue-light{
        width: 100px;
    }
}
.top-en{
    top: 7px;
}
.header-navbar-rht .en-dropdown .dropdown-menu{
    left:-70px !important;
	margin-top: 4px !important;
	border-radius: 5px;
	background-color: rgba(255, 255, 255, 1);
    padding: 13px;
}
.display-flex{
    min-width: 170px;
    span{
        float: left;
    }
}
.table-arrows{
    display: inline-grid;
    font-size: 11px;
    margin-top: -3px;
    float: left;
    .gray{
        color: gray;
    }
}
.color-box{
    width: 30px;
    height: 30px;
    border: 1px solid black;
    margin: 4px;
    float: left;
}
.custom-color-picker{
    width: 110px;
}
.Component-track-4{
    background-color: #757575 !important;
}
.Component-switchBase-2.Component-checked-5+.Component-track-4{
    background-color: rgba(14, 148, 231, 1) !important;
}
@media only screen and (max-width: 849.98px) {
    .table-arrows{
        display: none;
    }
}